import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useParams, useNavigate } from "react-router-dom";

import VideoModal from "../../components/admin/videos/VideoModal";
import {
  useGetVideoQuery,
  useUpdateVideoMutation,
} from "../../features/videos/videoApi";

const EditVideo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: video,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetVideoQuery(id);
  const [updateVideo] = useUpdateVideoMutation();

  const [isOpen, setIsOpen] = useState(true);

  const [videoData, setVideoData] = useState({
    title: "",
    description: "",
    url: "",
    views: "",
    duration: "",
  });

  const handleVideoSubmit = (e) => {
    e.preventDefault();
    // console.log(videoData);
    updateVideo({ id, ...videoData });
    setIsOpen(false);
  };

  const handleCloseBtn = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) navigate("/admin/videos");
  }, [isOpen, navigate]);

  useEffect(() => {
    if (!isLoading && isSuccess) setVideoData(video);
  }, [isLoading, isSuccess, video]);

  return (
    <>
      {isError && <div>{error.message}</div>}
      {!isLoading && isSuccess && (
        <ReactModal
          isOpen={isOpen}
          onRequestClose={handleCloseBtn}
          contentLabel="Add Video"
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          appElement={document.getElementById("root")}
        >
          <VideoModal
            handleVideoSubmit={handleVideoSubmit}
            videoData={videoData}
            setVideoData={setVideoData}
            setIsOpen={setIsOpen}
            isEdit={true}
          />
        </ReactModal>
      )}
    </>
  );
};

export default EditVideo;
