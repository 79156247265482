import { configureStore } from '@reduxjs/toolkit';

import { apiSlice } from "../features/api/apiSlice";
import authReducer from '../features/auth/authSlice';
import videoReducer from "../features/videos/videoSlice";
import assignmentMarkReducer from "../features/assignmentMarks/assignmentMarkSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    video: videoReducer,
    assignmentMarkCount: assignmentMarkReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware),
});
