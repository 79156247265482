/**
 * 
 * @param {String} description lorem ipsum dolor sit amet, consectetur adipiscing elit.
 * @param {Number} wordCount 8
 * @returns lorem ipsum dolor sit amet, consectetur adipiscing elit...
 */
export const truncateDescription = (description, wordCount = 8) => {
    const words = description.split(' ');
    if (words.length <= wordCount) return description;
    const truncatedWords = words.slice(0, wordCount);
    const truncatedDescription = truncatedWords.join(' ');
    return truncatedDescription + '...';
};

/**
 * 
 * @param {Array} array [{id: 1, name: 'foo'}, {id: 2, name: 'bar'}]
 * @param {Number} id 1
 * @returns Object {id: 1, name: 'foo'}
 */
export const getObjectFromArraybyID = (array, id) => {
    return array.find((item) => item.id === parseInt(id));
};

/**
 * 
 * @param {String} value option1_q3
 * @returns Number 3
 */
export const getIDfromString = (value) => {
    const splitValue = value.split('_');
    return parseInt(splitValue[1].substring(1));
};

/**
 * 
 * @param {Array} array1 [{
    "id": 1,
    "option": "A function that is called after a certain time interval",
    "isCorrect": true
    }]
 * @param {Array} array2 [{id: 1, value: true}, {id: 2, value: false}]
 */
export const compareTwoArrays = (array1, array2) => {
    let isCorrect = true;
    array1.forEach((item) => {
        const value = array2.find((i) => i.id === item.id).value;
        if (item.isCorrect !== value) {
            isCorrect = false;
        }
    });

    return isCorrect;
};