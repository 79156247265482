import React, { useState } from "react";
import ReactModal from "react-modal";

import VideoList from "../../components/admin/videos/VideoList";
import VideoModal from "../../components/admin/videos/VideoModal";
import { useAddVideoMutation } from "../../features/videos/videoApi";

const Videos = () => {
  const [addVideo] = useAddVideoMutation();
  const [isOpen, setIsOpen] = useState(false);

  const [videoData, setVideoData] = useState({
    title: "",
    description: "",
    url: "",
    views: "",
    duration: "",
  });

  const handleVideoSubmit = (e) => {
    e.preventDefault();
    const createdAt = new Date().toISOString();
    addVideo({ ...videoData, createdAt });
    setIsOpen(false);
  };

  return (
    <section className="py-6 bg-primary">
      <div className="mx-auto max-w-full px-5 lg:px-20">
        <div className="px-3 py-20 bg-opacity-10">
          <ReactModal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            contentLabel="Add Video"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            appElement={document.getElementById("root")}
          >
            <VideoModal
              handleVideoSubmit={handleVideoSubmit}
              videoData={videoData}
              setVideoData={setVideoData}
              setIsOpen={setIsOpen}
            />
          </ReactModal>
          <div className="w-full flex">
            <button className="btn ml-auto" onClick={() => setIsOpen(true)}>
              Add Video
            </button>
          </div>
          <div className="overflow-x-auto mt-4">
            <table className="divide-y-1 text-base divide-gray-600 w-full">
              <thead>
                <tr>
                  <th className="table-th">Video Title</th>
                  <th className="table-th">Description</th>
                  <th className="table-th">Action</th>
                </tr>
              </thead>

              <VideoList />
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Videos;
