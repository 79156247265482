import React, { useEffect, useState } from "react";
import { getIDfromString, compareTwoArrays } from "../../../utils";

const QuizItem = ({ quiz, setCorrectQuizes, setWrongQuizes, submitValue }) => {
  const { id, question, options } = quiz;
  const [selectedOptions, setSelectedOptions] = useState([
    { id: 1, value: false },
    { id: 2, value: false },
    { id: 3, value: false },
    { id: 4, value: false },
  ]);

  const handleChange = (e) => {
    const id = getIDfromString(e.target.id);
    const newSelectedOptions = selectedOptions.map((option) => {
      if (option.id === id) {
        return { ...option, value: e.target.checked };
      }
      return option;
    });

    setSelectedOptions(newSelectedOptions);
  };

  useEffect(() => {
    const handleSubmit = () => {
      const result = compareTwoArrays(options, selectedOptions);
      if (result) {
        setCorrectQuizes((prev) => [...prev, quiz]);
      } else {
        setWrongQuizes((prev) => [...prev, quiz]);
      }
    };

    if (submitValue) {
      handleSubmit();
    }
  }, [
    options,
    quiz,
    selectedOptions,
    setCorrectQuizes,
    setWrongQuizes,
    submitValue,
  ]);

  return (
    <div className="quiz">
      <h4 className="question">{question}</h4>
      <form className="quizOptions">
        {options.map((option) => (
          <label htmlFor={`option${id}_q${option.id}`} key={option.id}>
            <input
              type="checkbox"
              id={`option${id}_q${option.id}`}
              onChange={handleChange}
            />
            A function that is called after a certain time interval
          </label>
        ))}
      </form>
    </div>
  );
};

export default QuizItem;
