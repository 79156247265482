import { apiSlice } from "../api/apiSlice";

const quizMarkApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        addQuizMark: build.mutation({
            query: (quizMark) => ({
                url: "quizMark",
                method: "POST",
                body: quizMark,
            }),
        }),
    }),
});

export const { useAddQuizMarkMutation } = quizMarkApi;
