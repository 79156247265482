import React from "react";

const VideoModal = ({
  handleVideoSubmit,
  videoData,
  setVideoData,
  setIsOpen,
  isEdit = false,
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVideoData({ ...videoData, [name]: value });
  };

  return (
    <div>
      <form
        className="mt-8 space-y-6"
        action="#"
        method="POST"
        onSubmit={handleVideoSubmit}
      >
        <input type="hidden" name="remember" value="true" />
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="title" className="sr-only">
              Title
            </label>
            <input
              id="title"
              name="title"
              type="text"
              autoComplete="text"
              required
              className="login-input rounded-t-md"
              placeholder="Video title"
              value={videoData?.title}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="description" className="sr-only">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              type="text"
              maxLength={512}
              required
              className="login-input rounded-t-md"
              placeholder="Video description"
              value={videoData?.description}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="url" className="sr-only">
              URL
            </label>
            <input
              id="url"
              name="url"
              type="url"
              autoComplete="url"
              required
              className="login-input rounded-t-md"
              placeholder="Video url"
              value={videoData?.url}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="views" className="sr-only">
              Views
            </label>
            <input
              id="views"
              name="views"
              type="text"
              required
              className="login-input rounded-t-md"
              placeholder="Video views"
              value={videoData?.views}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="duration" className="sr-only">
              Duration
            </label>
            <input
              id="duration"
              name="duration"
              type="text"
              required
              className="login-input rounded-t-md"
              placeholder="Video duration"
              value={videoData?.duration}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-violet-600 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
          >
            {isEdit ? "Edit" : "Add"} Video
          </button>
        </div>
      </form>
      <button
        style={{
          color: "red",
          position: "absolute",
          top: 0,
          right: 25,
          transform: "translate(5%, -5%)",
        }}
        onClick={(e) => setIsOpen(false)}
      >
        Close X
      </button>
    </div>
  );
};

export default VideoModal;
