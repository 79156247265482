import React from "react";

import SideVideos from "./SideVideos";
import SelectedVideo from "./SelectedVideo";

const CoursePlayer = () => {
  return (
    <section className="py-6 bg-primary">
      <div className="mx-auto max-w-7xl px-5 lg:px-0">
        <div className="grid grid-cols-3 gap-2 lg:gap-8">
          <SelectedVideo />
          <SideVideos />
        </div>
      </div>
    </section>
  );
};

export default CoursePlayer;
