import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useParams, useNavigate } from "react-router-dom";

import AssignmentModal from "../../components/admin/assignments/AssignmentModal";
import {
  useGetAssignmentQuery,
  useUpdateAssignmentMutation,
} from "../../features/assignments/assignmentApi";

const EditAssignment = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: assignment,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetAssignmentQuery(id);
  const [updateAssignment] = useUpdateAssignmentMutation();

  const [isOpen, setIsOpen] = useState(true);

  const [assignmentData, setAssignmentData] = useState({
    title: "",
    video_id: 0,
    video_title: "",
    totalMark: 0,
  });

  const handleAssignmentSubmit = (e) => {
    e.preventDefault();
    let sid = parseInt(id);
    updateAssignment({ id: sid, ...assignmentData });
    setIsOpen(false);
  };

  const handleCloseBtn = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) navigate("/admin/assignments");
  }, [isOpen, navigate]);

  useEffect(() => {
    if (!isLoading && isSuccess)
      setAssignmentData({
        title: assignment.title,
        video_id: assignment.video_id,
        video_title: assignment.video_title,
        totalMark: assignment.totalMark,
      });
  }, [isLoading, isSuccess, assignment]);

  return (
    <>
      {isError && <div>{error.message}</div>}
      {!isLoading && isSuccess && (
        <ReactModal
          isOpen={isOpen}
          onRequestClose={handleCloseBtn}
          contentLabel="Edit Assignment"
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          appElement={document.getElementById("root")}
        >
          <AssignmentModal
            handleAssignmentSubmit={handleAssignmentSubmit}
            assignmentData={assignmentData}
            setAssignmentData={setAssignmentData}
            setIsOpen={setIsOpen}
            isEdit={true}
          />
        </ReactModal>
      )}
    </>
  );
};

export default EditAssignment;
