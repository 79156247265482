import React from "react";
import { Link } from "react-router-dom";

const CreateNewAccount = () => {
  return (
    <div className="flex items-center justify-end">
      <div className="text-sm">
        <Link
          to="/student/register"
          className="font-medium text-violet-600 hover:text-violet-500"
        >
          Create New Account
        </Link>
      </div>
    </div>
  );
};

export default CreateNewAccount;
