import React from "react";

import VideoItem from "./VideoItem";
import { useGetVideosQuery } from "../../../features/videos/videoApi";

const VideoList = () => {
  const {
    data: videos,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetVideosQuery();

  return (
    <tbody className="divide-y divide-slate-600/50">
      {isError && <p>{error.message}</p>}
      {!isLoading &&
        isSuccess &&
        videos.map((video) => <VideoItem key={video.id} video={video} />)}
    </tbody>
  );
};

export default VideoList;
