import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    total: 0,
    pending: 0
};

const assignmentMarkSlice = createSlice({
    name: "assignmentMarks",
    initialState,
    reducers: {
        setTotal: (state, action) => {
            state.total = action.payload;
        },
        setPending: (state, action) => {
            state.pending = action.payload;
        }
    }
});

export const { setTotal, setPending } = assignmentMarkSlice.actions;
export default assignmentMarkSlice.reducer;
