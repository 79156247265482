import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import QuizList from "../../components/student/quizes/QuizList";
import { useGetVideoQuery } from "../../features/videos/videoApi";

const Quiz = () => {
  const { id } = useParams();
  const { data: video, isSuccess } = useGetVideoQuery(id);
  const { title } = isSuccess && video;

  return (
    <section className="py-6 bg-primary">
      <div className="mx-auto max-w-7xl px-5 lg:px-0">
        <div className="mb-8">
          <h1 className="text-2xl font-bold">Quizzes for {title} Video</h1>
          <p className="text-sm text-slate-200">
            Each question contains 5 Mark
          </p>
        </div>

        <QuizList id={id} title={title} />
      </div>
    </section>
  );
};

export default Quiz;
