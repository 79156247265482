import React from "react";

const ForgotPassword = () => {
  return (
    <div className="flex items-center justify-end">
      <div className="text-sm">
        <p
          href="#"
          className="font-medium text-violet-600 hover:text-violet-500"
        >
          Forgot your password?
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
