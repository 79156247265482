import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentVideo: 1
};

const videoSlice = createSlice({
    name: "video",
    initialState,
    reducers: {
        setCurrentVideo: (state, action) => {
            state.currentVideo = action.payload;
        }
    }
});

export const { setCurrentVideo } = videoSlice.actions;
export default videoSlice.reducer;
