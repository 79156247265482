import React from "react";

const AssignmentSubmitModal = ({
  handleAssignmentSubmit,
  githubUrl,
  setGithubUrl,
}) => {
  return (
    <>
      <h2 align="center" style={{ color: "black" }}>
        Assignment Submission
      </h2>
      <br />
      <form onSubmit={handleAssignmentSubmit}>
        <div>
          <label htmlFor="duration" className="sr-only">
            GitHub Repo
          </label>
          <input
            name="githubUrl"
            id=""
            className="login-input rounded-t-md"
            required
            placeholder="GitHub Repo URL"
            value={githubUrl}
            onChange={(e) => setGithubUrl(e.target.value)}
          />
        </div>
        <br />
        <br />
        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-violet-600 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default AssignmentSubmitModal;
