import React from "react";

import { useGetQuizesQuery } from "../../../features/quizes/quizApi";
import QuizItem from "./QuizItem";

const QuizList = () => {
  const {
    data: quizes,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetQuizesQuery();

  return (
    <tbody className="divide-y divide-slate-600/50">
      {isLoading && (
        <tr>
          <td>Loading...</td>
        </tr>
      )}
      {isError && (
        <tr>
          <td>Error: {error}</td>
        </tr>
      )}
      {isSuccess && quizes.length > 0 ? (
        quizes.map((quiz) => <QuizItem key={quiz.id} quiz={quiz} />)
      ) : (
        <tr>
          <td>No Quiz Found!</td>
        </tr>
      )}
    </tbody>
  );
};

export default QuizList;
