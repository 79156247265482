import { apiSlice } from "../api/apiSlice";

const assignmentApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAssignments: builder.query({
            query: () => "/assignments",
        }),
        getAssignment: builder.query({
            query: (id) => `/assignments/${id}`,
            providesTags: (result, error, id) => ["Assignment", { type: "Assignment", id }],
        }),
        getAssignmentbyVideo: builder.query({
            query: (videoId) => `/assignments?video_id=${videoId}`,
        }),
        addAssignment: builder.mutation({
            query: (newAssignment) => ({
                url: "/assignments",
                method: "POST",
                body: newAssignment,
            }),

            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                let result = await queryFulfilled;

                dispatch(
                    apiSlice.util.updateQueryData("getAssignments", undefined, (draft) => {
                        let jsonDraft = JSON.parse(JSON.stringify(draft));

                        jsonDraft.push(result.data);

                        return jsonDraft;
                    })
                );
            }
        }),
        updateAssignment: builder.mutation({
            query: (updatedAssignment) => ({
                url: `/assignments/${updatedAssignment.id}`,
                method: "PUT",
                body: updatedAssignment,
            }),

            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                let result = await queryFulfilled;

                dispatch(
                    apiSlice.util.updateQueryData("getAssignments", undefined, (draft) => {
                        let jsonDraft = JSON.parse(JSON.stringify(draft));

                        jsonDraft = jsonDraft.map((assignment) => {
                            if (assignment.id === arg.id) {
                                return result.data;
                            }
                            return assignment;
                        });

                        return jsonDraft;
                    }
                    )
                );
            },

            invalidatesTags: (result, error, arg) => [{ type: "Assignment", id: arg.id }],
        }),
        deleteAssignment: builder.mutation({
            query: (id) => ({
                url: `/assignments/${id}`,
                method: "DELETE",
            }),

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const deleteAssignment = dispatch(
                    apiSlice.util.updateQueryData("getAssignments", undefined, (draft) => {
                        let jsonDraft = JSON.parse(JSON.stringify(draft));

                        jsonDraft = jsonDraft.filter((assignment) => assignment.id !== id);

                        return jsonDraft;
                    })
                );

                try {
                    await queryFulfilled;
                } catch (error) {
                    setTimeout(() => {
                        deleteAssignment.undo();
                    }, 2000);
                }
            }
        }),
    }),
});

export const {
    useGetAssignmentsQuery, useGetAssignmentQuery, useGetAssignmentbyVideoQuery,
    useAddAssignmentMutation, useUpdateAssignmentMutation, useDeleteAssignmentMutation
} = assignmentApi;