import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { setCurrentVideo } from "../../features/videos/videoSlice";
import { useGetVideosQuery } from "../../features/videos/videoApi";

const SideVideos = () => {
  const {
    data: videos,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetVideosQuery();
  const dispatch = useDispatch();
  const { currentVideo } = useSelector((state) => state.video);

  const handleClick = (id) => {
    dispatch(setCurrentVideo(id));
  };

  return (
    <>
      {isLoading && <div>Loading...</div>}
      {isError && <div>{error.message}</div>}
      {isSuccess && videos.length > 0 ? (
        <div className="col-span-full lg:col-auto max-h-[570px] overflow-y-auto bg-secondary p-4 rounded-md border border-slate-50/10 divide-y divide-slate-600/30">
          {videos.map((video) => {
            if (video.id === currentVideo) {
              return (
                <div
                  key={video.id}
                  className="w-full flex flex-row gap-2 cursor-pointer hover:bg-slate-900 p-2 py-3"
                >
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                    />
                  </svg>

                  <div clas="flex flex-col w-full">
                    <p className="text-slate-50 text-sm font-medium">
                      {video.title}
                    </p>
                    <div>
                      <span className="text-gray-400 text-xs mt-1">
                        {video.duration} Mins
                      </span>
                      <span className="text-gray-400 text-xs mt-1"> | </span>
                      <span className="text-gray-400 text-xs mt-1">
                        {video.views} views
                      </span>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={video.id}
                  className="w-full flex flex-row gap-2 cursor-pointer hover:bg-slate-900 p-2"
                  onClick={() => handleClick(video.id)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                    />
                  </svg>

                  <div className="flex flex-col w-full">
                    <p className="text-slate-50 text-sm font-medium">
                      {video.title}
                    </p>
                    <div>
                      <span className="text-gray-400 text-xs mt-1">
                        {video.duration} Mins
                      </span>
                      <span className="text-gray-400 text-xs mt-1"> | </span>
                      <span className="text-gray-400 text-xs mt-1">
                        {video.views} views
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      ) : (
        <div>No videos found</div>
      )}
    </>
  );
};

export default SideVideos;
