import { apiSlice } from "../api/apiSlice";
import { userLoggedIn } from "./authSlice";

const authApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (body) => ({
                url: "/login",
                method: "POST",
                body,
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                const result = await queryFulfilled;
                if (result) {
                    localStorage.setItem("accessToken", result.data.accessToken);
                    dispatch(userLoggedIn(result.data));
                }
            }
        }),
    })
});

export const { useLoginMutation } = authApi;