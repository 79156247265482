import React from "react";
import { useNavigate } from "react-router-dom";

import { useLoginMutation } from "../../features/auth/authApi";
import Login from "../../components/userManager/Login";

const StudentLogin = () => {
  const navigate = useNavigate();
  const [login] = useLoginMutation();

  const handleStudentLogin = (data) => {
    login(data);
    navigate("/student/dashboard");
  };

  return <Login formSubmit={handleStudentLogin} isAdmin={false} />;
};

export default StudentLogin;
