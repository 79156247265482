import React from "react";

import { getObjectFromArraybyID } from "../../../utils";
import { useGetVideosQuery } from "../../../features/videos/videoApi";

const AssignmentModal = ({
  handleAssignmentSubmit,
  assignmentData,
  setAssignmentData,
  setIsOpen,
  isEdit = false,
}) => {
  const { data: videos, isSuccess } = useGetVideosQuery();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "video") {
      const video = isSuccess && getObjectFromArraybyID(videos, value);
      setAssignmentData({
        ...assignmentData,
        video_id: video.id,
        video_title: video.title,
      });
      return;
    }
    if (name === "totalMark") {
      setAssignmentData({ ...assignmentData, [name]: parseInt(value) });
      return;
    }
    setAssignmentData({ ...assignmentData, [name]: value });
  };

  return (
    <div>
      <form
        className="mt-8 space-y-6"
        action="#"
        method="POST"
        onSubmit={handleAssignmentSubmit}
      >
        <input type="hidden" name="remember" value="true" />
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="title" className="sr-only">
              Title
            </label>
            <input
              id="title"
              name="title"
              type="text"
              autoComplete="text"
              required
              className="login-input rounded-t-md"
              placeholder="Video title"
              value={assignmentData?.title}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="duration" className="sr-only">
              Total Mark
            </label>
            <select
              name="video"
              id=""
              className="login-input rounded-t-md"
              required
              value={assignmentData?.video_id}
              onChange={handleInputChange}
            >
              <option hidden={true}>Select video</option>
              {isSuccess && videos.length > 0 ? (
                videos.map((video) => (
                  <option value={video.id} key={video.id}>
                    {video.title}
                  </option>
                ))
              ) : (
                <option>No video found...</option>
              )}
            </select>
          </div>
          <div>
            <label htmlFor="totalMark" className="sr-only">
              Total Mark
            </label>
            <input
              id="totalMark"
              name="totalMark"
              type="number"
              required
              className="login-input rounded-t-md"
              placeholder="Total mark"
              min={1}
              max={100}
              value={assignmentData?.totalMark}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-violet-600 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
          >
            {isEdit ? "Edit" : "Add"} Assignment
          </button>
        </div>
      </form>
      <button
        style={{
          color: "red",
          position: "absolute",
          top: 0,
          right: 25,
          transform: "translate(5%, -5%)",
        }}
        onClick={(e) => setIsOpen(false)}
      >
        Close X
      </button>
    </div>
  );
};

export default AssignmentModal;
