import React from "react";
import { useNavigate } from "react-router-dom";

import { useLoginMutation } from "../../features/auth/authApi";
import Login from "../../components/userManager/Login";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [login] = useLoginMutation();

  const handleAdminLogin = (data) => {
    login(data);
    navigate("/admin/dashboard");
  };

  return <Login formSubmit={handleAdminLogin} isAdmin={true} />;
};

export default AdminLogin;
