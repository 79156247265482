import { apiSlice } from "../api/apiSlice";

const videoApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getVideos: builder.query({
            query: () => "/videos",
        }),
        getVideo: builder.query({
            query: (id) => `/videos/${id}`,
            providesTags: (result, error, arg) => [{ type: "Video", id: arg }],
        }),
        addVideo: builder.mutation({
            query: (video) => ({
                url: "/videos",
                method: "POST",
                body: video,
            }),

            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                let result = await queryFulfilled;

                dispatch(
                    apiSlice.util.updateQueryData("getVideos", undefined, (draft) => {
                        let jsonDraft = JSON.parse(JSON.stringify(draft));

                        jsonDraft.push(result.data);

                        return jsonDraft;
                    })
                );
            }
        }),
        updateVideo: builder.mutation({
            query: (video) => ({
                url: `/videos/${video.id}`,
                method: "PUT",
                body: video,
            }),

            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                let result = await queryFulfilled;

                dispatch(
                    apiSlice.util.updateQueryData("getVideos", undefined, (draft) => {
                        let jsonDraft = JSON.parse(JSON.stringify(draft));

                        jsonDraft = jsonDraft.map((video) => {
                            if (video.id === arg.id) {
                                return result.data;
                            }
                            return video;
                        });

                        return jsonDraft;
                    }
                    )
                );
            },

            invalidatesTags: (result, error, arg) => ["Video", { type: "Video", id: arg.id }],
        }),
        deleteVideo: builder.mutation({
            query: (id) => ({
                url: `/videos/${id}`,
                method: "DELETE",
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const deleteVideo = dispatch(
                    apiSlice.util.updateQueryData("getVideos", undefined, (draft) => {
                        let jsonDraft = JSON.parse(JSON.stringify(draft));

                        jsonDraft = jsonDraft.filter((video) => video.id !== id);

                        return jsonDraft;
                    })
                );

                try {
                    await queryFulfilled;
                } catch (error) {
                    setTimeout(() => {
                        deleteVideo.undo();
                    }, 2000);
                }
            }
        }),

        invalidatesTags: (result, error, arg) => ["Video", { type: "Video", id: arg }],
    }),
});

export const {
    useGetVideosQuery, useGetVideoQuery,
    useAddVideoMutation, useUpdateVideoMutation, useDeleteVideoMutation
} = videoApi;
