import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useGetAssignmentMarksQuery } from "../../../features/assignmentMarks/assignmentMarkApi";
import {
  setTotal,
  setPending,
} from "../../../features/assignmentMarks/assignmentMarkSlice";
import AssignmentMarkItem from "./AssignmentMarkItem";

const AssignmentMarkList = () => {
  const dispatch = useDispatch();
  const {
    data: assignmentMarks,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAssignmentMarksQuery();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setTotal(assignmentMarks.length));
      dispatch(
        setPending(
          assignmentMarks.filter((am) => am.status === "pending").length
        )
      );
    }
  }, [assignmentMarks, dispatch, isSuccess]);

  return (
    <tbody className="divide-y divide-slate-600/50">
      {isLoading && (
        <tr>
          <td>Loading...</td>
        </tr>
      )}
      {isError && (
        <tr>
          <td>{error.message}</td>
        </tr>
      )}
      {isSuccess && assignmentMarks.length > 0 ? (
        assignmentMarks.map((assignmentMark) => (
          <AssignmentMarkItem
            key={assignmentMark.id}
            assignmentMark={assignmentMark}
          />
        ))
      ) : (
        <tr>
          <td>No Assignment Marks Found</td>
        </tr>
      )}
    </tbody>
  );
};

export default AssignmentMarkList;
