import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

const AdminDashboardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/admin" || location.pathname === "/admin/")
      navigate("/admin/dashboard");
  }, [location.pathname, navigate]);

  return <Outlet />;
};

export default AdminDashboardPage;
