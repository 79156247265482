import React, { useState } from "react";
import ReactModal from "react-modal";

import QuizList from "../../components/admin/quizes/QuizList";
import QuizModal from "../../components/admin/quizes/QuizModal";
import { useAddQuizMutation } from "../../features/quizes/quizApi";

const Quizes = () => {
  const [addQuiz] = useAddQuizMutation();
  const [isOpen, setIsOpen] = useState(false);

  const [quizData, setQuizData] = useState({
    question: "",
    video_id: 0,
    video_title: "",
    options: [
      {
        id: 1,
        option: "",
        isCorrect: false,
      },
      {
        id: 2,
        option: "",
        isCorrect: false,
      },
      {
        id: 3,
        option: "",
        isCorrect: false,
      },
      {
        id: 4,
        option: "",
        isCorrect: false,
      },
    ],
  });

  const handleQuizSubmit = (e) => {
    e.preventDefault();
    addQuiz(quizData);
    setIsOpen(false);
  };
  return (
    <section className="py-6 bg-primary">
      <div className="mx-auto max-w-full px-5 lg:px-20">
        <div className="px-3 py-20 bg-opacity-10">
          <ReactModal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            contentLabel="Add Assignment"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            appElement={document.getElementById("root")}
          >
            <QuizModal
              handleQuizSubmit={handleQuizSubmit}
              quizData={quizData}
              setQuizData={setQuizData}
              setIsOpen={setIsOpen}
            />
          </ReactModal>
          <div className="w-full flex">
            <button className="btn ml-auto" onClick={() => setIsOpen(true)}>
              Add Quiz
            </button>
          </div>
          <div className="overflow-x-auto mt-4">
            <table className="divide-y-1 text-base divide-gray-600 w-full">
              <thead>
                <tr>
                  <th className="table-th">Question</th>
                  <th className="table-th">Video</th>
                  <th className="table-th justify-center">Action</th>
                </tr>
              </thead>

              <QuizList />
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quizes;
