import React, { useState } from "react";

import { useUpdateAssignmentMarkMutation } from "../../../features/assignmentMarks/assignmentMarkApi";
import { toast } from "react-hot-toast";

const AssignmentMarkItem = ({ assignmentMark }) => {
  const [updateAssignmentMark] = useUpdateAssignmentMarkMutation();
  const {
    id,
    title,
    repo_link,
    student_name,
    status,
    mark,
    totalMark,
    createdAt,
  } = assignmentMark;
  const [givenMark, setGivenMark] = useState(mark);

  const submitMark = (e) => {
    e.preventDefault();
    if (parseInt(givenMark) > parseInt(totalMark) || parseInt(givenMark) < 0) {
      toast.error("Mark can't be greater than total mark or less than 0");
      return;
    }
    updateAssignmentMark({
      id,
      mark: parseInt(givenMark),
      status: "published",
    });
  };

  return (
    <tr>
      <td className="table-td">{title}</td>
      <td className="table-td">{createdAt}</td>
      <td className="table-td">{student_name}</td>
      <td className="table-td">{repo_link}</td>
      <td className={`table-td ${status === "pending" && "input-mark"}`}>
        {status === "pending" ? (
          <>
            <input
              max={totalMark}
              value={givenMark}
              onChange={(e) => setGivenMark(e.target.value)}
            />
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6 text-green-500 cursor-pointer hover:text-green-400"
              onClick={submitMark}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </>
        ) : (
          mark
        )}
      </td>
    </tr>
  );
};

export default AssignmentMarkItem;
