import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const StudentDashboardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/student" || location.pathname === "/student/")
      navigate("/student/course/player");
  }, [location.pathname, navigate]);

  return <Outlet />;
};

export default StudentDashboardPage;
