import { apiSlice } from "../api/apiSlice";

const assignmentMarkApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAssignmentMarks: build.query({
            query: () => ({
                url: "/assignmentMark",
                method: "GET",
            }),
        }),
        checkAssignmentMarkEntryforStudent: build.query({
            query: ({ studentID, assignmentID }) => ({
                url: `/assignmentMark?student_id=${studentID}&assignment_id=${assignmentID}`,
                method: "GET",
            }),
        }),
        createAssignmentMark: build.mutation({
            query: (assignmentMark) => ({
                url: "/assignmentMark",
                method: "POST",
                body: assignmentMark,
            }),

            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                let result = await queryFulfilled;

                dispatch(
                    apiSlice.util.updateQueryData("getAssignmentMarks", undefined, (draft) => {
                        let jsonDraft = JSON.parse(JSON.stringify(draft));

                        jsonDraft.push(result.data);

                        return jsonDraft;
                    })
                );
            }
        }),
        updateAssignmentMark: build.mutation({
            query: (assignmentMark) => ({
                url: `/assignmentMark/${assignmentMark.id}`,
                method: "PATCH",
                body: assignmentMark,
            }),

            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                let result = await queryFulfilled;

                dispatch(
                    apiSlice.util.updateQueryData("getAssignmentMarks", undefined, (draft) => {
                        let jsonDraft = JSON.parse(JSON.stringify(draft));

                        jsonDraft = jsonDraft.map((assignmentMark) => {
                            if (assignmentMark.id === arg.id) {
                                return result.data;
                            }
                            return assignmentMark;
                        });

                        return jsonDraft;
                    }
                    )
                );
            }
        }),
    })
});

export const {
    useGetAssignmentMarksQuery, useUpdateAssignmentMarkMutation, useCheckAssignmentMarkEntryforStudentQuery,
    useCreateAssignmentMarkMutation
} = assignmentMarkApi;
