import React, { useEffect, useState } from "react";

import QuizItem from "./QuizItem";
import { useGetQuizbyVideoQuery } from "../../../features/quizes/quizApi";
import { useAddQuizMarkMutation } from "../../../features/quizMark/quizMarkApi";

const QuizList = ({ id, title }) => {
  const {
    data: quizes,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetQuizbyVideoQuery(id);
  const [addQuizMark] = useAddQuizMarkMutation();

  const [submitValue, setSubmitValue] = useState(false);

  const [correctQuizes, setCorrectQuizes] = useState([]);
  const [wrongQuizes, setWrongQuizes] = useState([]);

  const quizLength = isSuccess && quizes.length;

  const handleSubmit = () => {
    setSubmitValue(!submitValue);
  };

  useEffect(() => {
    if (correctQuizes.length + wrongQuizes.length === quizLength) {
      const finalData = {
        student_id: 4,
        student_name: "Other One",
        video_id: parseInt(id),
        video_title: title,
        totalQuiz: quizLength,
        totalCorrect: correctQuizes.length,
        totalWrong: wrongQuizes.length,
        totalMark: quizLength * 5,
        mark: correctQuizes.length * 5,
      };

      addQuizMark(finalData);
    } else if (correctQuizes.length + wrongQuizes.length > quizLength) {
      setCorrectQuizes([]);
      setWrongQuizes([]);
    }
  }, [addQuizMark, correctQuizes, id, quizLength, title, wrongQuizes]);

  return (
    <div className="space-y-8 ">
      {isLoading && <div>Loading...</div>}
      {isError && <div>{error.message}</div>}
      {isSuccess && quizes.length > 0 ? (
        quizes.map((quiz) => (
          <QuizItem
            key={quiz.id}
            quiz={quiz}
            setCorrectQuizes={setCorrectQuizes}
            setWrongQuizes={setWrongQuizes}
            submitValue={submitValue}
          />
        ))
      ) : (
        <div>No Quiz Found</div>
      )}
      <button
        className="px-4 py-2 rounded-full bg-cyan block ml-auto mt-8 hover:opacity-90 active:opacity-100 active:scale-95 "
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
};

export default QuizList;
