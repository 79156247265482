import React, { useState } from "react";
import ReactModal from "react-modal";

import AssignmentList from "../../components/admin/assignments/AssignmentList";
import AssignmentModal from "../../components/admin/assignments/AssignmentModal";
import { useAddAssignmentMutation } from "../../features/assignments/assignmentApi";

const Assignments = () => {
  const [addAssignment] = useAddAssignmentMutation();
  const [isOpen, setIsOpen] = useState(false);

  const [assignmentData, setAssignmentData] = useState({
    title: "",
    video_id: 0,
    video_title: "",
    totalMark: 0,
  });

  const handleAssignmentSubmit = (e) => {
    e.preventDefault();
    // console.log(assignmentData);
    addAssignment(assignmentData);
    setIsOpen(false);
  };
  return (
    <section className="py-6 bg-primary">
      <div className="mx-auto max-w-full px-5 lg:px-20">
        <div className="px-3 py-20 bg-opacity-10">
          <ReactModal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            contentLabel="Add Assignment"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            appElement={document.getElementById("root")}
          >
            <AssignmentModal
              handleAssignmentSubmit={handleAssignmentSubmit}
              assignmentData={assignmentData}
              setAssignmentData={setAssignmentData}
              setIsOpen={setIsOpen}
            />
          </ReactModal>
          <div className="w-full flex">
            <button className="btn ml-auto" onClick={() => setIsOpen(true)}>
              Add Assignment
            </button>
          </div>
          <div className="overflow-x-auto mt-4">
            <table className="divide-y-1 text-base divide-gray-600 w-full">
              <thead>
                <tr>
                  <th className="table-th">Title</th>
                  <th className="table-th">Video Title</th>
                  <th className="table-th">Mark</th>
                  <th className="table-th">Action</th>
                </tr>
              </thead>
              <AssignmentList />
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Assignments;
