import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import AdminDashboardPage from "./pages/admin/AdminDashboardPage";
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminLogin from "./pages/admin/AdminLogin";
import Videos from "./pages/admin/Videos";
import EditVideo from "./pages/admin/EditVideo";
import Assignments from "./pages/admin/Assignments";
import EditAssignment from "./pages/admin/EditAssignment";
import Quizes from "./pages/admin/Quizes";
import EditQuiz from "./pages/admin/EditQuiz";
import AssignmentMarks from "./pages/admin/AssignmentMarks";

import StudentDashboardPage from "./pages/student/StudentDashboardPage";
import CoursePlayer from "./components/student/CoursePlayer";
import StudentLogin from "./pages/student/StudentLogin";
import StudentRegister from "./pages/student/StudentRegister";
import Quiz from "./pages/student/Quiz";

import Error404 from "./pages/Error404";
import Navbar from "./components/admin/Navbar";
import Home from "./pages/Home";
import { Toaster } from "react-hot-toast";

const App = () => {
    return <Router>
        <Navbar />
        <Toaster />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="admin" element={<AdminDashboardPage />}>
                <Route path="login" element={<AdminLogin />} />
                <Route path="dashboard" element={<AdminDashboard />} />

                <Route path="videos" element={<Videos />} />
                <Route path="videos/edit/:id" element={<EditVideo />} />

                <Route path="assignments" element={<Assignments />} />
                <Route path="assignments/edit/:id" element={<EditAssignment />} />

                <Route path="quizes" element={<Quizes />} />
                <Route path="quizes/edit/:id" element={<EditQuiz />} />

                <Route path="assignments/mark" element={<AssignmentMarks />} />
            </Route>
            <Route path="student" element={<StudentDashboardPage />}>
                <Route path="register" element={<StudentRegister />} />
                <Route path="login" element={<StudentLogin />} />
                <Route path="course/player" element={<CoursePlayer />} />
                <Route path="video/:id/quiz" element={<Quiz />} />
            </Route>
            <Route path="*" element={<Error404 />} />
        </Routes>
    </Router>;
};

export default App;