import { apiSlice } from "../api/apiSlice";

const quizApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getQuizes: builder.query({
            query: () => "/quizzes",
        }),
        getQuiz: builder.query({
            query: (quizId) => `/quizzes/${quizId}`,
            providesTags: (result, error, quizId) => ["Quiz", { type: 'Quiz', id: quizId }],
        }),
        getQuizbyVideo: builder.query({
            query: (videoId) => `/quizzes?video_id=${videoId}`,
        }),
        addQuiz: builder.mutation({
            query: (quiz) => ({
                url: "/quizzes",
                method: "POST",
                body: quiz,
            }),

            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                let result = await queryFulfilled;

                dispatch(
                    apiSlice.util.updateQueryData("getQuizes", undefined, (draft) => {
                        let jsonDraft = JSON.parse(JSON.stringify(draft));

                        jsonDraft.push(result.data);

                        return jsonDraft;
                    })
                );
            }
        }),
        updateQuiz: builder.mutation({
            query: (quiz) => ({
                url: `/quizzes/${quiz.id}`,
                method: "PUT",
                body: quiz,
            }),

            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                let result = await queryFulfilled;

                dispatch(
                    apiSlice.util.updateQueryData("getQuizes", undefined, (draft) => {
                        let jsonDraft = JSON.parse(JSON.stringify(draft));

                        jsonDraft = jsonDraft.map((quiz) => {
                            if (quiz.id === arg.id) {
                                return result.data;
                            }
                            return quiz;
                        });

                        return jsonDraft;
                    }
                    )
                );
            },

            invalidatesTags: (result, error, quiz) => [{ type: 'Quiz', id: quiz.id }],
        }),
        deleteQuiz: builder.mutation({
            query: (quizId) => ({
                url: `/quizzes/${quizId}`,
                method: "DELETE",
            }),

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const deleteQuiz = dispatch(
                    apiSlice.util.updateQueryData("getQuizes", undefined, (draft) => {
                        let jsonDraft = JSON.parse(JSON.stringify(draft));

                        jsonDraft = jsonDraft.filter((quiz) => quiz.id !== id);

                        return jsonDraft;
                    })
                );

                try {
                    await queryFulfilled;
                } catch (error) {
                    setTimeout(() => {
                        deleteQuiz.undo();
                    }, 2000);
                }
            }
        }),
    }),
});

export const {
    useGetQuizesQuery, useGetQuizQuery, useGetQuizbyVideoQuery,
    useAddQuizMutation, useUpdateQuizMutation, useDeleteQuizMutation
} = quizApi;