import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";

import {
  useGetQuizQuery,
  useUpdateQuizMutation,
} from "../../features/quizes/quizApi";
import QuizModal from "../../components/admin/quizes/QuizModal";

const EditQuiz = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: quiz,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetQuizQuery(id);
  const [updateQuiz] = useUpdateQuizMutation();

  const [isOpen, setIsOpen] = useState(true);

  const [quizData, setQuizData] = useState({
    question: "",
    video_id: 0,
    video_title: "",
    options: [],
  });

  const handleQuizSubmit = (e) => {
    e.preventDefault();
    updateQuiz(quizData);
    setIsOpen(false);
  };

  const handleCloseBtn = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) navigate("/admin/quizes");
  }, [isOpen, navigate]);

  useEffect(() => {
    if (!isLoading && isSuccess) setQuizData(quiz);
  }, [isLoading, isSuccess, quiz]);

  return (
    <>
      {isError && <div>{error.message}</div>}
      {!isLoading && isSuccess && (
        <ReactModal
          isOpen={isOpen}
          onRequestClose={handleCloseBtn}
          contentLabel="Edit Assignment"
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          appElement={document.getElementById("root")}
        >
          <QuizModal
            handleQuizSubmit={handleQuizSubmit}
            quizData={quizData}
            setQuizData={setQuizData}
            setIsOpen={setIsOpen}
            isEdit={true}
          />
        </ReactModal>
      )}
    </>
  );
};

export default EditQuiz;
