import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetVideoQuery } from "../../features/videos/videoApi";
import { useGetAssignmentbyVideoQuery } from "../../features/assignments/assignmentApi";
import {
  useCreateAssignmentMarkMutation,
  useCheckAssignmentMarkEntryforStudentQuery,
} from "../../features/assignmentMarks/assignmentMarkApi";
import AssignmentSubmitModal from "./AssignmentSubmitModal";

const SelectedVideo = () => {
  const { currentVideo } = useSelector((state) => state.video);
  const [createAssignmentMark] = useCreateAssignmentMarkMutation();
  const {
    data: video,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetVideoQuery(currentVideo);

  const [holdAssignment, setHoldAssignment] = useState(true);
  const [disableAssignment, setDisableAssignment] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [githubUrl, setGithubUrl] = useState("");

  const {
    data: assignment,
    isSuccess: isAssignmentSuccess,
    isLoading: isAssignmentLoading,
    isError: isAssignmentError,
    error: assignmentError,
  } = useGetAssignmentbyVideoQuery(currentVideo, {
    skip: holdAssignment,
  });

  const [assignmentValue, setAssignmentValue] = useState(skipToken);
  const result = useCheckAssignmentMarkEntryforStudentQuery({
    studentID: 4,
    assignmentID: assignmentValue.id,
  });

  const handleAssignmentSubmit = (e) => {
    e.preventDefault();
    createAssignmentMark({
      student_id: 4,
      student_name: "Another Hasan",
      assignment_id: assignment[0].id,
      title: assignment[0].title,
      createdAt: new Date().toISOString(),
      totalMark: assignment[0].totalMark,
      mark: 0,
      repo_link: githubUrl,
      status: "pending",
    });
    setDisableAssignment(true);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isSuccess) setHoldAssignment(false);
  }, [isSuccess]);

  useEffect(() => {
    if (isAssignmentSuccess) setAssignmentValue(assignment[0]);
  }, [assignment, isAssignmentSuccess]);

  useEffect(() => {
    if (result.isSuccess) setDisableAssignment(result.data.length > 0);
  }, [result.data, result.isSuccess]);

  return (
    <>
      {isLoading && <div>Loading...</div>}
      {isError && <div>{error.message}</div>}
      {isSuccess && (
        <div className="col-span-full w-full space-y-8 lg:col-span-2">
          <iframe
            width="100%"
            className="aspect-video"
            src={video.url}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          ></iframe>

          <div>
            <h1 className="text-lg font-semibold tracking-tight text-slate-100">
              {video.title}
            </h1>
            <h2 className=" pb-4 text-sm leading-[1.7142857] text-slate-400">
              Uploaded on {video.createdAt}
            </h2>

            <div className="flex gap-4">
              <ReactModal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                contentLabel="Assignment View"
                appElement={document.getElementById("root")}
              >
                <AssignmentSubmitModal
                  handleAssignmentSubmit={handleAssignmentSubmit}
                  setGithubUrl={setGithubUrl}
                />
              </ReactModal>
              {isAssignmentLoading && <div>Loading...</div>}
              {isAssignmentError && <div>{assignmentError.message}</div>}
              {isAssignmentSuccess && assignment.length > 0 && (
                <button
                  className="px-3 font-bold py-1 border border-cyan text-cyan rounded-full text-sm hover:bg-cyan hover:text-primary"
                  onClick={() => setIsOpen(true)}
                  disabled={disableAssignment}
                >
                  এসাইনমেন্ট
                </button>
              )}

              <Link
                to={`/student/video/${video.id}/quiz`}
                className="px-3 font-bold py-1 border border-cyan text-cyan rounded-full text-sm hover:bg-cyan hover:text-primary"
              >
                কুইজে অংশগ্রহণ করুন
              </Link>
            </div>
            <p className="mt-4 text-sm text-slate-400 leading-6">
              {video.description}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectedVideo;
