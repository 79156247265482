import React, { useEffect, useState } from "react";

const OptionsForm = ({ option, setQuizData }) => {
  const [optionData, setOptionData] = useState(option);

  const handleOptionChange = (e) => {
    const { name, value } = e.target;
    if (name === "isCorrect") {
      setOptionData({ ...optionData, [name]: e.target.checked });
    } else {
      setOptionData({ ...optionData, [name]: value });
    }
  };

  useEffect(() => {
    setQuizData((prev) => {
      const newOptions = prev.options.map((option) => {
        if (option.id === optionData.id) {
          return optionData;
        }
        return option;
      });

      return { ...prev, options: newOptions };
    });
  }, [optionData, setQuizData]);

  return (
    <div style={{ display: "flex" }}>
      <label htmlFor="optionText" className="sr-only">
        Options
      </label>
      <input
        id="optionText"
        name="option"
        type="text"
        required
        className="login-input rounded-t-md"
        placeholder={`Option ${option.id} - Select checkbox if correct`}
        value={optionData.option}
        onChange={handleOptionChange}
        style={{ flex: "1" }}
      />
      <input
        type="checkbox"
        name="isCorrect"
        checked={optionData.isCorrect}
        onChange={handleOptionChange}
        style={{ backgroundColor: "green", marginLeft: "10px" }}
      />
    </div>
  );
};

export default OptionsForm;
