import React from "react";

import { useGetAssignmentsQuery } from "../../../features/assignments/assignmentApi";
import AssignmentItem from "./AssignmentItem";

const AssignmentList = () => {
  const {
    data: assignments,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAssignmentsQuery();

  return (
    <tbody className="divide-y divide-slate-600/50">
      {isLoading && (
        <tr>
          <td>Loading...</td>
        </tr>
      )}
      {isError && (
        <tr>
          <td>{error.message}</td>
        </tr>
      )}
      {isSuccess && assignments.length > 0 ? (
        assignments.map((assignment) => (
          <AssignmentItem key={assignment.id} assignment={assignment} />
        ))
      ) : (
        <tr>
          <td>No assignments found</td>
        </tr>
      )}
    </tbody>
  );
};

export default AssignmentList;
